import { useEffect, useState, useRef } from "react";
import storage from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Button from "./Button";
import uuid from "react-uuid";

export default function FileUpload({ labelStyle, documents, setDocuments }) {
  // State to store uploaded file
  const [files, setFiles] = useState([]);

  const [uploadFinished, setUploadFinished] = useState(false);

  // progress
  const [percent, setPercent] = useState(0);

  const MAX_FILES = 7;

  // Handle file upload event and update state
  function handleChange(event) {
    if (Array.from(event.target.files).length > MAX_FILES) {
      event.preventDefault();
      alert(`Sie können maximal ${MAX_FILES} Dokumente hochladen`);
      return;
    }
    setFiles(event.target.files);
  }

  const handleUpload = () => {
    if (files.length === 0) {
      alert("Bitte laden Sie erst eine PDF-Datei hoch!");
    }

    let fileUrls = [];
    // Create unique folder name
    const folderName = uuid();

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    Array.from(files).forEach((file) => {
      const storageRef = ref(
        storage,
        `/friedenspreis/${folderName}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            fileUrls.push(url);
            if (fileUrls.length === files.length) {
              setDocuments(fileUrls);
              setUploadFinished(true);
            }
          });
        }
      );
    });
  };

  useEffect(() => {
    if (files.length === 0) {
      return;
    }
    handleUpload();
  }, [files]);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const [hideProgressbar, setHideProgressbar] = useState(false);

  useEffect(() => {
    if (uploadFinished) {
      setTimeout(() => {
        setHideProgressbar(true);
      }, 1000);
    }
  }, [uploadFinished]);

  return (
    <div>
      <label for={"documents"} className={labelStyle}>
        <div>
          {
            "Bitte fügen Sie mit diesem Antrag einen Kostenplan, der die Personal- und Materialkosten des Projektes abbildet, bei."
          }
          {<span className="text-red-600"> *</span>}
        </div>
        {files.length !== undefined && (
          <>
            {files.length === MAX_FILES ? (
              <div className="text-red-600">
                {files.length + "/" + MAX_FILES} Dokumenten
              </div>
            ) : (
              <div>{files.length + "/" + MAX_FILES} Dokumenten</div>
            )}
          </>
        )}
      </label>
      <>
        <Button
          onClick={handleClick}
          buttonText="Dateien hochladen"
          bgColor="red-600"
          bgHoverColor="red-700"
          borderColor="transparent"
          textColor="white"
          fontWeight="medium"
          px="8"
          py="3"
          mt="6"
          ml="0"
          width="full"
          ringColor="red-500"
        />
        <input
          type="file"
          style={{ display: "none" }}
          multiple
          accept=".pdf"
          ref={hiddenFileInput}
          onChange={handleChange}
        />
      </>
      {files.length > 0 ? (
        !hideProgressbar ? (
          <div className="mt-4 w-full h-3 border border-gray-300 rounded-md">
            <div
              className="bg-red-600 h-full rounded-md"
              style={{ width: `${percent}%` }}
            ></div>
          </div>
        ) : (
          <p className="text-xs mt-2">☑️ Alle Dateien wurden hochgeladen.</p>
        )
      ) : (
        <p className="text-xs mt-2">Keine Dateien hochgeladen.</p>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// assets
import buerger_stiftung_muenster from "../assets/buerger_stiftung_muenster.png";

// services
import applicationService from "../services/applicationService.js";

export default function Resume() {
  let { id } = useParams();
  {
    /* <span>{new Date(date).toLocaleDateString("de-DE")}</span> */
  }

  const [number, setNumber] = useState();
  const [name, setName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectURL, setProjectURL] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [projectIdea, setProjectIdea] = useState("");
  const [region, setRegion] = useState("");
  const [targetGroup, setTargetGroup] = useState("");
  const [challenges, setChallenges] = useState("");
  const [sustainability, setSustainability] = useState("");
  const [startDate, setStartDate] = useState();
  const [currentVolunteerCount, setCurrentVolunteerCount] = useState();
  const [currentEmployeeCount, setCurrentEmployeeCount] = useState();
  const [projectAim, setProjectAim] = useState("");
  const [cooperationPartner, setCooperationPartner] = useState("");
  const [projectFinancing, setProjectFinancing] = useState("");
  const [projectUSP, setProjectUSP] = useState("");
  const [ToSAgreement, setToSAgreement] = useState(false);
  const [marketingChannel, setMarketingChannel] = useState("");
  const [creationDate, setCreationDate] = useState();

  useEffect(() => {
    // get document by id
    const req = applicationService.getApplication(id);
    req.then((res) => {
      // set state variables
      const newData = res.data.data;
      setNumber(newData.number);
      setName(newData.name);
      setProjectName(newData.projectName);
      setProjectURL(newData.projectURL);
      setContactPerson(newData.contactPerson);
      setStreet(newData.street);
      setHouseNumber(newData.houseNumber);
      setZipCode(newData.zipCode);
      setCity(newData.city);
      setEmail(newData.email);
      setTelephone(newData.telephone);
      setProjectIdea(newData.projectIdea);
      setRegion(newData.region);
      setTargetGroup(newData.targetGroup);
      setChallenges(newData.challenges);
      setSustainability(newData.sustainability);
      setStartDate(newData.startDate);
      setCurrentVolunteerCount(newData.currentVolunteerCount);
      setCurrentEmployeeCount(newData.currentEmployeeCount);
      setProjectAim(newData.projectAim);
      setCooperationPartner(newData.cooperationPartner);
      setProjectFinancing(newData.projectFinancing);
      setProjectUSP(newData.projectUSP);
      setToSAgreement(newData.ToSAgreement);
      setMarketingChannel(newData.marketingChannel);
      setCreationDate(newData.creationDate);
    });
    req.catch((err) => {
      console.error("err", err);
    });
  });

  return (
    <>
      <div className="overflow-hidden w-2100 h-2970 flex flex-col justify-between pt-12 pl-24 pr-8 pb-8">
        {/* content */}
        <div className="w-full flex flex-col justify-start">
          {/* header */}
          <div>
            <div className="w-full flex justify-start items-center">
              <img
                src={buerger_stiftung_muenster}
                width="120"
                alt="Logo"
                className="my-auto cursor-pointer"
              />
              <div className="pl-4 text-gray-500 font-bold">
                {/* Bewerbung 1234 vom 26.06.2022 */}
                Bewerbung {number} vom{" "}
                {new Date(creationDate).toLocaleDateString("de-DE")}
              </div>
            </div>
          </div>
          {/* Body */}
          {/* general information */}
          <div className="w-full flex flex-col">
            <div className="text-gray-500 pb-4 text-sm pt-10">
              Allgemeine Informationen
            </div>
            <div className="w-full flex flex-col">
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Name des Trägers
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{name}</div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Projektname
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{projectName}</div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Internet-Adresse
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{projectURL}</div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Ansprechpartner
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{contactPerson}</div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Adresse
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {street + " " + houseNumber + ", " + zipCode + " " + city}{" "}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  E-Mail
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{email}</div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Telefon
                </div>
                <div className="w-4/5 flex pl-2 text-xs">{telephone}</div>
              </div>
            </div>
            {/* project information 1. page */}
            <div className="text-gray-500 pb-4 text-sm pt-10">
              Projektinformationen
            </div>
            <div className="w-full flex flex-col">
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Projektidee
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {projectIdea}
                  {/* Actify is a music recommender, which provides its user with
                  highly personalized playlists. In order to enhance the users
                  music experience, we base our recommendations on an activity
                  mood or music flavor the user wants its music to be for. This
                  allows us to carefully create a playlist that is well suited
                  for the users need. Actify is a music recommender, which
                  provides its user with highly personalized playlists. In order
                  to enhance the users music experience, we base our
                  recommendations on an activity mood or music flavor the user
                  wants its music to be for. This allows us to carefully create
                  a playlist that is well suited for the users need. Actify is a
                  music recommender, which provides its user with highly
                  personalized playlists. In order to enhance the users music
                  experience, we base our recommendations on an activity mood or
                  music flavor the user wants its music to be for. This allows
                  us to carefully create a playlist that is well suited for the
                  users need. Actify is a music recommender, which provides its
                  user with highly personalized playlists. In order to enhance
                  the users music experience, we base our recommendations on an
                  activity mood or music flavor the user wants its music to be
                  for. This allows us to carefully create a playlist that is
                  well suited for the users need. Actify is a music recommender,
                  which provides its user with highly personalized playlists. In
                  order to enhance the users music experience, we base our
                  recommendations on an activity mood or music flavor the user
                  wants its music to be for. This allows us to carefully create
                  a playlist that is well suited for the users need. Actify is a
                  music recommender, which provides its user with highly order
                  to enhance the users music experience, we base our */}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Zielgruppe
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {targetGroup}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  vitae sem id justo convallis eleifend. Nunc mattis est at
                  purus tincidunt, in interdum ex lacinia. Suspendisse potenti.
                  Suspendisse potenti. Orci varius natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Vivamus
                  malesuada rhoncus sodales. Curabitur id nunc vitae metus
                  egestas aliquet. Aliquam enim tellus, maximus eu venenatis in,
                  consectetur quis mauris. Ut aliquam sem vel purus imperdiet
                  rhoncus. Cras scelerisq */}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Projektregion
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {region}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  vitae sem id justo convallis eleifend. Nunc mattis est at
                  purus tincidunt, in interdum ex lacinia. Suspendisse potenti.
                  Suspendisse potenti. Orci varius natoque penatibus et magnis
                  dis parturient montes, nascetur ridiculus mus. Vivamus
                  malesuada rhoncus sodales. Curabitur id nunc vitae metus
                  egestas aliquet. Aliquam enim tellus, maximus eu venenatis in,
                  consectetur quis mauris. Ut aliquam sem vel purus imperdiet
                  rhoncus. Cras scelerisq */}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                  Projektstart
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {new Date(startDate).toLocaleDateString("de-DE")}
                  {/* 04.01.2022 */}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                  <div>Anzahl</div>
                  <div>Ehrenamtlicher</div>
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {currentVolunteerCount}
                  {/* 2 */}
                </div>
              </div>
              <div className="w-full flex flex-row pb-1">
                <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                  <div>Anzahl bezahlter</div>
                  <div>Kräfte</div>
                </div>
                <div className="w-4/5 flex pl-2 text-xs">
                  {currentEmployeeCount}
                  {/* 0 */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full flex flex-row justify-center items-center">
          <div className="text-xs font-light text-gray-500">Seite 1</div>
          <div className="text-xs font-light text-gray-500 pl-1">
            - Bewerbung von
          </div>
          <div className="text-xs font-bold text-gray-500 pl-1">
            {name}
            {/* Springboard GmbH */}
          </div>
        </div>
      </div>
      <div className="overflow-hidden w-2100 h-2970 flex flex-col justify-between pt-12 pl-24 pr-8 pb-8">
        {/* content */}
        <div className="w-full flex flex-col justify-start">
          {/* header */}
          <div>
            <div className="w-full flex justify-start items-center">
              <img
                src={buerger_stiftung_muenster}
                width="120"
                alt="Logo"
                className="my-auto cursor-pointer"
              />
              <div className="pl-4 text-gray-500 font-bold">
                {/* Bewerbung 1234 vom 26.06.2022 */}
                Bewerbung {number} vom{" "}
                {new Date(creationDate).toLocaleDateString("de-DE")}
              </div>
            </div>
          </div>
          {/* project information 2. page */}
          <div className="text-gray-500 pb-4 text-sm pt-10">
            Projektinformationen
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Herausforderungen</div>
                <div>des Projektes</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {challenges}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vitae sem id justo convallis eleifend. Nunc mattis est at purus
                tincidunt, in interdum ex lacinia. Suspendisse potenti.
                Suspendisse potenti. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Vivamus malesuada
                rhoncus sodales. Curabitur id nunc vitae metus egestas aliquet.
                Aliquam enim tellus, maximus eu venenatis in, consectetur quis
                mauris. Ut aliquam sem vel purus imperdiet rhoncus. Cras
                scelerisque id diam non pellentesque. Nulla ligula tellus,
                ultrices sit amet maximus at, lacinia et risus. Mauris vel odio
                sed felis hendrerit fermentum sed vitae ante. Donec eget velit
                vestibulum, aliquet neque id, dignissim metus. Praesent leo
                magna, suscipit malesuada mi vitae, placerat viverra augue.
                Nullam pharetra, lectus non convallis porttitor, mauris turpis
                aliquet tellus, quis laoreet quam tortor eu est.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nam vitae sem id
                justo convallis el */}
              </div>
            </div>
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Bedingungen</div>
                <div>des Projektes</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {sustainability}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vitae sem id justo convallis eleifend. Nunc mattis est at purus
                tincidunt, in interdum ex lacinia. Suspendisse potenti.
                Suspendisse potenti. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Vivamus malesuada
                rhoncus sodales. Curabitur id nunc vitae metus egestas aliquet.
                Aliquam enim tellus, maximus eu venenatis in, consectetur quis
                mauris. Ut aliquam sem vel purus imperdiet rhoncus. Cras
                scelerisque id diam non pellentesque. Nulla ligula tellus,
                ultrices sit amet maximus at, lacinia et risus. Mauris vel odio
                sed felis hendrerit fermentum sed vitae ante. Donec eget velit
                vestibulum, aliquet neque id, dignissim metus. Praesent leo
                magna, suscipit malesuada mi vitae, placerat viverra augue.
                Nullam pharetra, lectus non convallis porttitor, mauris turpis
                aliquet tellus, quis laoreet quam tortor eu est.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nam vitae sem id
                justo convallis el */}
              </div>
            </div>
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Ziel des</div>
                <div>Projektes</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {projectAim}
                {/* Actify is a music recommender, which provides its user with
                highly personalized playlists. In order to enhance the users
                music experience, we base our recommendations on an activity
                mood or music flavor the user wants its music to be for. This
                allows us to carefully create a playlist that is well suited for
                the users need. Actify is a music recommender, which provides
                its user with highly personalized playlists. In order to enhance
                the users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly personalized playlists. In order
                to enhance the users music experience, we base our
                recommendations on an activity mood or music flavor the user
                wants its music to be for. This allows us to carefully create a
                playlist that is well suited for the users need. Actify is a
                music recommender, which provides its user with highly
                personalized playlists. In order to enhance the users music
                experience, we base our recommendations on an activity mood or
                music flavor the user wants its music to be for. This allows us
                to carefully create a playlist that is well suited for the users
                need. Actify is a music recommender, which provides its user
                with highly personalized playlists. In order to enhance the
                users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly order to enhance the users music
                experience, we base our */}
              </div>
            </div>
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex justify-end pr-2 text-gray-500 font-bold text-xs">
                Kooperationspartner
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {cooperationPartner}
                {/* Janus Innovation GmbH */}
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full flex flex-row justify-center items-center">
          <div className="text-xs font-light text-gray-500">Seite 2</div>
          <div className="text-xs font-light text-gray-500 pl-1">
            - Bewerbung von
          </div>
          <div className="text-xs font-bold text-gray-500 pl-1">
            {name}
            {/* Springboard GmbH */}
          </div>
        </div>
      </div>
      <div className="overflow-hidden w-2100 h-2970 flex flex-col justify-between pt-12 pl-24 pr-8 pb-8">
        {/* content */}
        <div className="w-full flex flex-col justify-start">
          {/* header */}
          <div>
            <div className="w-full flex justify-start items-center">
              <img
                src={buerger_stiftung_muenster}
                width="120"
                alt="Logo"
                className="my-auto cursor-pointer"
              />
              <div className="pl-4 text-gray-500 font-bold">
                {/* Bewerbung 1234 vom 26.06.2022 */}
                Bewerbung {number} vom{" "}
                {new Date(creationDate).toLocaleDateString("de-DE")}
              </div>
            </div>
          </div>
          {/* project information 2. page */}
          <div className="text-gray-500 pb-4 text-sm pt-10">
            Projektinformationen
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Finanzierung des</div>
                <div>Projektes</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {projectFinancing}
                {/* Actify is a music recommender, which provides its user with
                highly personalized playlists. In order to enhance the users
                music experience, we base our recommendations on an activity
                mood or music flavor the user wants its music to be for. This
                allows us to carefully create a playlist that is well suited for
                the users need. Actify is a music recommender, which provides
                its user with highly personalized playlists. In order to enhance
                the users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly personalized playlists. In order
                to enhance the users music experience, we base our
                recommendations on an activity mood or music flavor the user
                wants its music to be for. This allows us to carefully create a
                playlist that is well suited for the users need. Actify is a
                music recommender, which provides its user with highly
                personalized playlists. In order to enhance the users music
                experience, we base our recommendations on an activity mood or
                music flavor the user wants its music to be for. This allows us
                to carefully create a playlist that is well suited for the users
                need. Actify is a music recommender, which provides its user
                with highly personalized playlists. In order to enhance the
                users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly order to enhance the users music
                experience, we base our which provides its user with highly
                personalized playlists. In order to enhance the users music
                experience, we base our recommendations on an activity mood or
                music flavor the user wants its music to be for. This allows us
                to carefully create a playlist that is well suited for the users
                need. Actify is a music recommender, which provides its user
                with highly order to enhance the users music experience, we base
                our */}
              </div>
            </div>
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Alleinstellungsmerkmal</div>
                <div>des Projektes</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {projectUSP}
                {/* Actify is a music recommender, which provides its user with
                highly personalized playlists. In order to enhance the users
                music experience, we base our recommendations on an activity
                mood or music flavor the user wants its music to be for. This
                allows us to carefully create a playlist that is well suited for
                the users need. Actify is a music recommender, which provides
                its user with highly personalized playlists. In order to enhance
                the users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly personalized playlists. In order
                to enhance the users music experience, we base our
                recommendations on an activity mood or music flavor the user
                wants its music to be for. This allows us to carefully create a
                playlist that is well suited for the users need. Actify is a
                music recommender, which provides its user with highly
                personalized playlists. In order to enhance the users music
                experience, we base our recommendations on an activity mood or
                music flavor the user wants its music to be for. This allows us
                to carefully create a playlist that is well suited for the users
                need. Actify is a music recommender, which provides its user
                with highly personalized playlists. In order to enhance the
                users music experience, we base our recommendations on an
                activity mood or music flavor the user wants its music to be
                for. This allows us to carefully create a playlist that is well
                suited for the users need. Actify is a music recommender, which
                provides its user with highly order to enhance the users music
                experience, we base ourwhich provides its user with highly
                personalized playlists. In order to enhance the users music
                experience, we base our recommendations on an activity mood or
                music flavor the user wants its music to be for. This allows us
                to carefully create a playlist that is well suited for the users
                need. Actify is a music recommender, which provides its user
                with highly order to enhance the users music experience, we base
                our */}
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full flex flex-row justify-center items-center">
          <div className="text-xs font-light text-gray-500">Seite 3</div>
          <div className="text-xs font-light text-gray-500 pl-1">
            - Bewerbung von
          </div>
          <div className="text-xs font-bold text-gray-500 pl-1">
            {name}
            {/* Springboard GmbH */}
          </div>
        </div>
      </div>
      <div className="overflow-hidden w-2100 h-2970 flex flex-col justify-between pt-12 pl-24 pr-8 pb-8">
        {/* content */}
        <div className="w-full flex flex-col justify-start">
          {/* header */}
          <div>
            <div className="w-full flex justify-start items-center">
              <img
                src={buerger_stiftung_muenster}
                width="120"
                alt="Logo"
                className="my-auto cursor-pointer"
              />
              <div className="pl-4 text-gray-500 font-bold">
                {/* Bewerbung 1234 vom 26.06.2022 */}
                Bewerbung {number} vom{" "}
                {new Date(creationDate).toLocaleDateString("de-DE")}
              </div>
            </div>
          </div>
          {/* project information 2. page */}
          <div className="text-gray-500 pb-4 text-sm pt-10">
            Projektinformationen
          </div>
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Einverstanden mit</div>
                <div>Veröffentlichung</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {ToSAgreement ? "Ja" : "Nein"}
                {/* Ja */}
              </div>
            </div>
            <div className="w-full flex flex-row pb-1">
              <div className="w-1/5 flex flex-col items-end pr-2 text-gray-500 font-bold text-xs">
                <div>Vom Friedenspreis</div>
                <div>erfahren durch</div>
              </div>
              <div className="w-4/5 flex pl-2 text-xs">
                {marketingChannel}
                {/* WWU Münster Prof. Dr. Gottfired Vossen */}
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full flex flex-row justify-center items-center">
          <div className="text-xs font-light text-gray-500">Seite 4</div>
          <div className="text-xs font-light text-gray-500 pl-1">
            - Bewerbung von
          </div>
          <div className="text-xs font-bold text-gray-500 pl-1">
            {name}
            {/* Springboard GmbH */}
          </div>
        </div>
      </div>
    </>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = initializeApp({
  apiKey: "AIzaSyCksdDrInEeSluJOh4OhAgrhzwSlr61vzI",
  authDomain: "friedens-preis-muenster.firebaseapp.com",
  projectId: "friedens-preis-muenster",
  storageBucket: "friedens-preis-muenster.appspot.com",
  messagingSenderId: "79249880275",
  appId: "1:79249880275:web:3d708f42068a47bb459026",
  measurementId: "G-7RXZMTB4N2"
});

// Initialize Firebase
const storage = getStorage(app);
export default storage;